import { ModuleWithProviders, NgModule } from '@angular/core';

import { WindowToken, windowProvider } from '../lib/services/window.service';
import { CookieService } from './services/cookie.service';

@NgModule({
  imports: [],
  exports: [],
})
export class NgShellModule {
  static forRoot(): ModuleWithProviders<NgShellModule> {
    return {
      ngModule: NgShellModule,
      providers: [
        { provide: WindowToken, useFactory: windowProvider },
        CookieService
      ]
    };
  }
}
