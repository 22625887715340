import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationStart, Router, RouterEvent } from '@angular/router';

import {
  AuthService,
  LanguageKey,
  ShellEvent,
  ShellListenerEvent,
  ThemeService,
  Translation,
} from '@s2a/core';
import { FileStateService, FileQuery } from '@s2a-core-akita/files';
import {
  CookieService,
  SessionService,
  S2A_LAST_USED_APP,
  showLanguageDialog,
} from '@s2a/ng-shell';

import { RouterService } from '../../services/router.service';
import { PageLoaderService } from '../../services/pageLoader.service';
import { environment } from '../../../environments/environment';
import { AppRegistryService } from '../../services/app-registry.service';
import { getSessionByRight } from '../../config/sessions.config';
import { getKroneEshopUrl } from '../../config/shop.config';
import { SidenavItem } from '../../models/sidenav-item.model';
import { Notification } from '../../enum/notification.enum';
import { HistoryItemService } from '../../services/history-item.service';
import { SideNavGroupItem } from '../../models/side-nav-group-item.model';

@UntilDestroy()
@Component({
  selector: 's2a-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() isAuthenticated: boolean;
  @Output() appBellClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('languageDialog', { static: true })
  languageDialog: TemplateRef<any>;
  userImageUrl: string;
  baseImageUrl: string;
  sidenavItems: SideNavGroupItem[];
  appBellItem: SidenavItem;
  currentLanguageKey: LanguageKey;
  activeAppName = 'drinktec-welcome';

  constructor(
    private authService: AuthService,
    private fileStateService: FileStateService,
    private fileQuery: FileQuery,
    private sessionService: SessionService,
    private appRegistryService: AppRegistryService,
    private translateService: Translation,
    private themeService: ThemeService,
    private dialog: MatDialog,
    public pageLoaderService: PageLoaderService,
    private router: Router,
    private routerService: RouterService,
    private historyItemService: HistoryItemService
  ) {
    this.baseImageUrl = environment.imageUrl;
    this.sidenavItems = this.appRegistryService.sidenavItems;
    this.appBellItem = this.appRegistryService.appBell;
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof ActivationStart) {
        this.activeAppName = event.snapshot.root?.firstChild.data.appUrl;
      }
    });
    this.loadUserImageUrl();
    this.currentLanguageKey = this.translateService.getCurrentLanguage();
    this.getCount();
  }

  private getCount(): void {
    // if (this.authService.hasGlobalRight(APPBELL_RIGHT)) {
      this.historyItemService.getUnseenCount().subscribe((count: number) => {
        this.setBadgeCount(count);
      });
    // }
  }

  private setBadgeCount(count: number) {
    const unseenNotificationCount = count > Notification.UNSEEN_THRESHOLD
    ? `+${Notification.UNSEEN_THRESHOLD}` : (count === 0 ? '' : count);
    this.appBellItem.badgeCount = unseenNotificationCount;
  }

  private async loadUserImageUrl(): Promise<void> {
    if (this.userPictureId) {
      this.fileStateService.ensureCache([this.userPictureId]).toPromise();
      this.fileQuery.selectFileUrl(this.userPictureId).subscribe((url) => {
        if (url) {
          this.userImageUrl = url;
        }
      });
    }
  }

  async onProfileClicked(): Promise<void> {
    await this.onItemClicked('profile');
  }

  async onItemClicked(app: string): Promise<void> {
    switch (app) {
      case 'sessions':
        return this.openInNewTab(this.sessionsUrl);
      case 'krones-shop':
        return this.openInNewTab(this.kronesEShopUrl);
      case 'toggle-theme':
        return this.toggleTheme();
      case 'language-selection':
        return this.changeLanguage();
      case 'logout':
        return this.logout();
      case 'app-bell':
        this.toggleAppBell();
        break;
      default:
        this.toggleAppBell(true);
        this.activeAppName = app;
        return await this.openApp(app);
    }
  }

  /**
   * The function is used to emit an event to the parent component to toggle the app bell side bar
   *
   * @param forciblyHide Boolean to denote if the panel forcibly should be closed if opened
   */
  private toggleAppBell(forciblyHide: boolean = false): void {
    this.historyItemService.updateHistories().subscribe( () => {
      this.setBadgeCount(0);
    });
    this.appBellClicked.emit(forciblyHide);
  }

  private async openApp(app: string): Promise<void> {
    const isMigratedRoute =
      true || (await this.pageLoaderService.isMigratedApp(app));
    if (isMigratedRoute) {
      this.router.navigate([app]);
    } else {
      this.routerService.redirectToApp(app);
    }
  }

  private logout(): void {
    this.authService.logout();
  }

  private changeLanguage(): void {
    showLanguageDialog(this.dialog, this.languageDialog)
      .pipe(untilDestroyed(this))
      .subscribe((language: LanguageKey) => {
        this.currentLanguageKey = language;
        this.translateService.switchLanguage(language);
        if (language) {
          window.postMessage(
            {
              type: 'language',
              value: language,
            } as ShellListenerEvent,
            '*'
          );
        }
      });
  }

  private toggleTheme(): void {
    window.postMessage(
      {
        type: ShellEvent.ThemeChange,
        value: this.themeService.getAlternateTheme(),
      } as ShellListenerEvent,
      '*'
    );
  }

  private openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  get sessionsUrl(): string {
    const rights = this.sessionService.getUserInformation().globalRights || [];
    return getSessionByRight(rights);
  }

  get kronesEShopUrl(): string {
    return getKroneEshopUrl(this.translateService.getCurrentLanguage());
  }

  get userPictureId(): string | undefined {
    return this.sessionService.getUserInformation().picture;
  }

  get userInitials() {
    const user = this.sessionService.getUserInformation();
    return (user.firstname || '')
      .charAt(0)
      .concat((user.lastname || '').charAt(0))
      .toUpperCase();
  }
}
