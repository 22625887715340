import { PlatformApplication } from '../models/platform.models';

export const AppShellApplications: PlatformApplication[] = [
  {
    key: 'profile',
    enabledByRight: 'core-user-management-list-users',
    routePath: 'profile'
  },
  {
    key: 'user-management',
    enabledByRight: 'core-user-management-list-users',
    routePath: 'user-management'
  },
  {
    key: 'equipment',
    enabledByRight: 'core-equipment-modify',
    routePath: 'equipment'
  },
  {
    key: 'storage',
    enabledByRight: 'storage-show-storage',
    routePath: 'storage'
  },
  {
    key: 'maintenance',
    enabledByRight: 'maintenance-use-maintenance',
    routePath: 'maintenance'
  },
  {
    key: 'performance',
    enabledByRight: 'use-performance',
    routePath: 'performance'
  },
  {
    key: 'connect',
    enabledByRight: 'read-timeline',
    routePath: 'connect'
  },
  {
    key: 'v2-watchdog',
    enabledByRight: 'v2-watchdog-view',
    routePath: 'v2-watchdog'
  },
  {
    key: 'task-service',
    enabledByRight: 'use-task-service',
    routePath: 'task-service'
  },
  {
    key: 'contiloop-ai',
    enabledByRight: 'show-contiloop-service',
    text: 'Contiloop AI',
    routePath: 'contiloop-ai'
  },
  {
    key: 'performance',
    enabledByRight: 'use-performance',
    text: 'Advanced Analytics',
    routePath: 'advanced-analytics-performance'
  },
  {
    key: 'erp-analytics',
    enabledByRight: 'use-erp-analytics',
    text: 'Integrated Analytics',
    routePath: 'integrated-erp-analytics'
  },
  {
    key: 'track-and-trace',
    enabledByRight: 'use-erp-analytics',
    text: 'Track and Trace',
    routePath: 'track-and-trace'
  },
  {
    key: 'lms',
    enabledByRight: 'use-erp-analytics',
    text: 'Order Management',
    routePath: 'lms'
  },
  {
    key: 'task-service',
    enabledByRight: 'use-task-service',
    text: 'Tasks',
    routePath: 'guidance-task-service'
  },
  {
    key: 'v2-watchdog',
    enabledByRight: 'v2-watchdog-view',
    text: 'Watchdog',
    routePath: 'guidance-v2-watchdog'
  },
  {
    key: 'connect',
    enabledByRight: 'read-timeline',
    text: 'Connect',
    routePath: 'guidance-connect'
  },
];
