<ng-container
  *ngIf="openTargetInNewWindow === true; then router; else listItem"
></ng-container>

<ng-template #router>
  <a [routerLink]="['/', routePath]" routerLinkActive="active">
    <ng-container *ngTemplateOutlet="listItem"></ng-container>
  </a>
</ng-template>

<ng-template #listItem>
  <mat-list-item
    translate
    class="enable-touch"
    [class.flex-end]="!showText"
    (click)="onItemClicked($event, app)"
    [matTooltip]="showText ? null : text"
    matTooltipPosition="after"
    matTooltipClass="tooltip"
  >
    <div class="icon-container">
      <mat-icon [svgIcon]="icon" [class.selected]="isAppBellVisible"></mat-icon>
      <span class="badge" *ngIf="badgeCount && badgeCount > 0">{{badgeCount}}</span>
    </div>
    <span *ngIf="showText" class="expandable-text">{{text}}</span>
  </mat-list-item>
</ng-template>
