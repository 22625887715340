import { Observable } from 'rxjs/internal/Observable';

import { TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LanguageKey } from '@s2a/core';

/**
 * Handels Language Selector dialog which returns the Language as an Observable<Language>
 *
 * @params {MatDialog} dialog
 * @params {TemplateRef} template
 * @returns Observable<Language>
 */
export function showLanguageDialog(dialog: MatDialog, template: TemplateRef<any>): Observable<LanguageKey> {
  const dialogRef = dialog.open(template, {
    width: '260px',
    maxHeight: '95vh',
    minHeight: '20vh',
    data: {},
  });
  return new Observable((observer) => {
    document.addEventListener('language-selected-event', (e: any) => {
      const language: LanguageKey = e.detail.languageKey;
      dialogRef.close(language);
      observer.next(language);
    });
  });

}
