<cdk-virtual-scroll-viewport itemSize="80">
  <ng-container *ngFor="let timelineGroup of timelineGroups">
    <s2a-pure-history-divider
      [separatorText]="timelineGroup?.title"
    ></s2a-pure-history-divider>
    <ng-container *ngFor="let timeline of timelineGroup.items">
        <s2a-pure-history-item
          [service]="timeline?.service"
          [timestamp]="timeline?.timestamp"
          [title]="timeline?.title | translate"
          [description]="timeline?.description | translate"
          [iconUrl]="timeline?.iconUrl"
          [titleLink]="timeline?.titleLink"
          [isRead]="timeline?.readStatus === 'READ'"
          (linkClicked)="onTimelineClick($event)"
          (switchReadStatus)="onSwitchReadStatus($event)"
        >
        </s2a-pure-history-item>
    </ng-container>
  </ng-container>
  <mat-progress-bar mode="query" *ngIf="loadingPaginatedData"></mat-progress-bar>
</cdk-virtual-scroll-viewport>
