import { tap, catchError } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { AuthService, ShellEvent, LOCALSTORAGE_USER_SESSION } from '@s2a/core';

import { SessionService } from '@s2a/ng-shell';

import { PageLoaderService } from './services/pageLoader.service';
import { RouterService } from './services/router.service';
import { ShellAuthenticationService } from './services/shell-authentication.service';
import { HeartBeatService } from './services/heartbeat.service';

@UntilDestroy()
@Component({
  selector: 's2a-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  lastValue: any;
  appBellOpened = false;

  @ViewChild('authPlace', { static: true }) authPlace: ElementRef;

  constructor(
    private authService: AuthService,
    private heartBeatService: HeartBeatService,
    private sessionService: SessionService,
    private routerService: RouterService,
    public pageLoaderService: PageLoaderService,
    public shellAuthenticationService: ShellAuthenticationService
  ) {}

  ngOnInit() {
    this.authService.isLoggedIn$
      .pipe(
        untilDestroyed(this),
        tap((isLogin: boolean) => {
          this.isAuthenticated = isLogin;
          if (!isLogin) {
            this.sessionService.clearSession();
            this.routerService.redirectToLogin();
          }
        })
      )
      .subscribe();

    this.shellAuthenticationService
      .refreshRights()
      .pipe(
        untilDestroyed(this),
        tap(() => {
          if (!this.isAuthenticated) {
            this.authService.isLoggedIn = true;
          }
          this.isAuthenticated = true;
        }),
        catchError(() => {
          this.authService.isLoggedIn = false;
          this.isAuthenticated = false;
          return 'Failed to refresh user';
        })
      )
      .subscribe();

    this.authService.hasGlobalRights$
      .pipe(untilDestroyed(this))
      .subscribe((globalRights) => {
        let user = this.authService.user;
        user = { ...user, globalRights };
        this.sessionService.setUserInformation(user);
      });

    this.heartBeatService.heartBeat().pipe(untilDestroyed(this)).subscribe();

    const userSession = sessionStorage.getItem(LOCALSTORAGE_USER_SESSION);
    if (
      !userSession ||
      Object.keys(userSession).length === 0
    ) {
      this.authService.isLoggedIn = false;
      this.isAuthenticated = false;
    }

    let previousUrl = '';
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === ShellEvent.RouteChange) {
          if (event.data.value !== previousUrl) {
            this.routerService.changeRoute(event);
            previousUrl = event.data.value;
          }
        }
        if (event.data.type === ShellEvent.UserChange) {
          this.shellAuthenticationService
            .fetchUserWithRights(this.authService.user.userId)
            .pipe(
              untilDestroyed(this),
              catchError(() => {
                this.authService.isLoggedIn = false;
                this.isAuthenticated = false;
                return 'Failed to fetch user with rights';
              })
            )
            .subscribe();
        }
      },
      false
    );
  }

  /**
   * The function is used to toggle app bell side bar
   */
  toggleAppBellHandler(forciblyHide: boolean): void {
    this.appBellOpened = forciblyHide ? !forciblyHide : !this.appBellOpened;
  }
}
