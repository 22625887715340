import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Injectable, Inject } from '@angular/core';

import { WindowToken } from '@s2a/ng-shell';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private routeSubject = new Subject<any>();

  constructor(
    @Inject(WindowToken) private window: Window
  ) { }

  redirectToLogin() {
    this.window.location.href = environment.domainUrl.concat(this.generateUri('drinktec-login'));
  }

  redirectToApp(app: string) {
    this.window.location.href = environment.domainUrl.concat(app);
  }

  changeRoute(message: MessageEvent) {
    this.routeSubject.next(message);
  }

  getActiveRoute$(): Observable<MessageEvent> {
    return this.routeSubject.asObservable();
  }

  private generateUri(prefix: string) {
    const currentPath = this.window.location.pathname + this.window.location.search;
    if (currentPath === '/') {
      return prefix;
    }
    return `${prefix}?redirect=${encodeURIComponent(currentPath)}`;
  }

}
