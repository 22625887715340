import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionService } from '@s2a/ng-shell';

import '@s2a-shared-elements/button';
import '@s2a-shared-components/language-selector';
import '@s2a-shared-components/base-reference';
import '@s2a-shared-elements/date-range-picker';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { User } from './app/models/user.model';

interface AuthResponse {
  TokenType: 'Bearer';
  IdToken: string;
  AccessToken: string;
  ExpiresIn: number;
  RefreshToken: string;
  token: string;
}

if (environment.production) {
  enableProdMode();
}

// App Bootstrapping
const bootstrapApp = () => {
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
};

// Api call
const getCall = async (apiUrl: string, token: string) => {
  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
};

const postCall = async (apiUrl: string, userCred: { code: string, accountId: string }) => {
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(userCred)
  });
};

// Api call to get User's info and rights
const userInfo = async (token: string, userId: string): Promise<User & { globalRights: string[] }> => {
  const userPromise = getCall(`${environment.apiUrl}users/${userId}`, token);
  const rightsPromise = getCall(`${environment.apiUrl}users/me/global-rights`, token);

  const [userResultResponse, rightsResultResponse] = await Promise.all([userPromise, rightsPromise]);

  const user = await userResultResponse.json() as User & { username?: string };
  const rights = await rightsResultResponse.json() as string[];

  return { ...user, login: user.username, globalRights: rights };
};

const authInfo = async (userCred) => {
  const authResult = await postCall(`${environment.apiUrl}auth/drinktec/tokens`, userCred);

  const authInformation: any = await authResult.json() as AuthResponse;
  let userId = authInformation.userId;
  if (authInformation.AccessToken) {
    const jwt = new JwtHelperService();
    userId = jwt.decodeToken(authInformation.IdToken)['custom:userId'];
  }
  const userDetails = await userInfo(authInformation.token, userId);
  return {
    ...userDetails, ...{
      IdToken: authInformation.token,
      RefreshToken: authInformation.RefreshToken,
      AccessToken: authInformation.AccessToken
    }
  };
};

// Check if there is any Router Fragment for Authentication Setup
const routeFragment = new URLSearchParams(location.hash.slice(1));
const routeParams = new URLSearchParams(location.search.slice(1));

if (routeFragment.has('idToken') && routeFragment.has('accessToken') && routeFragment.has('refreshToken')) {
  const sessionService = new SessionService();
  const token = routeFragment.get('idToken');
  const jwt = new JwtHelperService();
  const userId = jwt.decodeToken(token)['custom:userId'];
  userInfo(token, userId).then((data) => {
    sessionService.setSession({
      ...data, ...{
        IdToken: token,
        AccessToken: routeFragment.get('accessToken'),
        RefreshToken: routeFragment.get('refreshToken')
      }
    });
    location.hash = '';
    bootstrapApp();
  });
} else if (routeParams.has('code') && routeParams.has('accountId')) {
  authInfo({
    code: routeParams.get('code'),
    accountId: routeParams.get('accountId')
  }).then((data) => {
    const sessionService = new SessionService();
    sessionService.setSession(data);
    location.search = '';
    bootstrapApp();
  });
} else {
  bootstrapApp();
}
