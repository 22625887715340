import { Injectable } from '@angular/core';
import { flatten } from '@angular/compiler';

import { PageLoaderService } from './pageLoader.service';

import { SidenavItem } from '../models/sidenav-item.model';
import { AppRegistry } from '../models/app-registry.model';
import { SideNavGroupItem } from '../models/side-nav-group-item.model';

export enum ItemSection {
  S2a = 0,
  Core = 1,
  External = 2,
  Action = 3,
  ANALYTICS = 4,
  SHOPFLOOR_GUIDANCE = 5,
  OPTIMIZATION = 6,
  LINE_CONTROL = 7
}

@Injectable({
  providedIn: 'root',
})
export class AppRegistryService {

  constructor(
    private pageLoaderService: PageLoaderService) { }


  get sidenavItems(): SideNavGroupItem[] {
    // return flatten([this.getS2aItems(), this.getCoreItems(), this.getExternalItems(), this.getActionItems()]);
    return [this.getS2aItems(), this.getAnalyticsItems(), this.getShopFloorGuidanceItems(),
            this.getOptimizationItems(), this.getLineControlItems(), this.getCoreItems(), this.getExternalItems(), this.getActionItems()
           ];
  }

  get appBell(): SidenavItem {
    let item: SidenavItem;
    this.pageLoaderService.components.forEach((app: AppRegistry) => {
      item = {
        id: app.id,
        textKey: `apps.${app.key}`,
        icon: app.icon,
        canOpenInNewWindow: false,
        section: ItemSection.Action,
      };
    });
    return item;
  }

  private getS2aItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (!this.isCoreService(app.key) && !this.isAnalyticsItem(app.routePath) && !this.isLineControlItem(app.routePath)
          && !this.isOptimizationItems(app.key) && !this.isShopfloorGuidanceItems(app.routePath)) {
        if (!this.isBlacklistApp(app.id)) {
          items.push({
            id: app.id,
            textKey: `apps.${app.key}`,
            icon: app.icon,
            canOpenInNewWindow: true,
            section: ItemSection.S2a,
            routePath: app.routePath
          });
        }
      }
    });

    return {
      groupName: 'Share2Act Services',
      displayLocaleText: true,
      items
    };
  }

  private getCoreItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];
    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isCoreService(app.key) && !this.isAnalyticsItem(app.routePath) && !this.isShopfloorGuidanceItems(app.routePath)
          && !this.isOptimizationItems(app.key)) {
          items.push({
            id: app.id,
            textKey: `apps.${app.key}`,
            icon: app.icon,
            canOpenInNewWindow: true,
            section: ItemSection.Core,
            routePath: app.routePath
          });
        }
    });

    return {
      groupName: '',
      displayLocaleText: true,
      items
    };
  }

  private getAnalyticsItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (!this.isCoreService(app.key) && this.isAnalyticsItem(app.routePath)) {
        items.push({
          id: app.id,
          textKey: ``,
          icon: 'performance',
          canOpenInNewWindow: true,
          text: app.text,
          section: ItemSection.ANALYTICS,
          routePath: app.routePath
        });
      }
    });

    return {
      groupName: 'Analytics',
      displayLocaleText: false,
      items
    };
  }


  private getLineControlItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (!this.isCoreService(app.key) && this.isLineControlItem(app.routePath)) {
        items.push({
          id: app.id,
          textKey: ``,
          icon: 'equipment_line',
          canOpenInNewWindow: true,
          text: app.text,
          section: ItemSection.LINE_CONTROL,
          routePath: app.routePath
        });
      }
    });

    return {
      groupName: 'Line Control',
      displayLocaleText: false,
      items
    };
  }

  private getShopFloorGuidanceItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isShopfloorGuidanceItems(app.routePath)) {
        items.push({
          id: app.id,
          textKey: `apps.${app.key}`,
          icon: app.icon,
          canOpenInNewWindow: true,
          section: ItemSection.SHOPFLOOR_GUIDANCE,
          routePath: app.routePath
        });
      }
    });

    return {
      groupName: 'Shopfloor Guidance',
      displayLocaleText: true,
      items
    };
  }

  private getOptimizationItems(): SideNavGroupItem {
    const items: SidenavItem[] = [];

    this.pageLoaderService.registeredApps.forEach((app: AppRegistry) => {
      if (this.isOptimizationItems(app.key)) {
        items.push({
          id: app.id,
          textKey: ``,
          icon: app.icon,
          canOpenInNewWindow: true,
          text: app.text,
          section: ItemSection.OPTIMIZATION,
          routePath: app.routePath
        });
      }
    });

    return {
      groupName: 'Optimization',
      displayLocaleText: false,
      items
    };
  }

  private getExternalItems(): SideNavGroupItem {
    return {
      groupName: '',
      displayLocaleText: true,
      items :
      [
        {
          id: 'sessions',
          textKey: 'apps.sessions',
          icon: 'sessions',
          canOpenInNewWindow: false,
          section: ItemSection.External
        },
        {
          id: 'krones-shop',
          textKey: 'components.platform_header.krones_eshop',
          icon: 'storage_cart',
          canOpenInNewWindow: false,
          section: ItemSection.External
        }
      ]
    };
  }

  private getActionItems(): SideNavGroupItem {
    return {
      groupName: '',
      displayLocaleText: true,
      items :
      [
        {
          id: 'toggle-theme',
          textKey: 'components.platform_header.toggle_theme',
          icon: 'toggleTheme',
          canOpenInNewWindow: false,
          section: ItemSection.Action
        },
        {
          id: 'language-selection',
          textKey: 'components.platform_header.change_language',
          icon: 'language',
          canOpenInNewWindow: false,
          section: ItemSection.Action
        },
        {
          id: 'logout',
          textKey: 'components.platform_header.logout',
          icon: 'logout',
          canOpenInNewWindow: false,
          section: ItemSection.Action
        }
      ]
    };
  }

  private isBlacklistApp(appId: string): boolean {
    return (
      appId === 'notification-service' ||
      appId === 'sessions' ||
      appId === 'profile'
    );
  }

  private isCoreService(appId: string): boolean {
    return (
      appId === 'user-management' ||
      appId === 'equipment' ||
      appId === 'notifications' ||
      appId === 'external-api'
    );
  }

  private isAnalyticsItem(routePath: string): boolean {
    return (
      routePath === 'advanced-analytics-performance' ||
      routePath === 'integrated-erp-analytics' ||
      routePath === 'track-and-trace'
    );
  }

  private isLineControlItem(routePath: string): boolean {
    return (
      routePath === 'lms'
    );
  }

  private isShopfloorGuidanceItems(routePath: string): boolean {
    return (
      routePath === 'guidance-task-service' ||
      routePath === 'guidance-v2-watchdog' ||
      routePath === 'guidance-connect'
    );
  }

  private isOptimizationItems(appId: string): boolean {
    return (
      appId === 'contiloop-ai'
    );
  }
}
