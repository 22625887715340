import { Apollo, QueryRef } from 'apollo-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable, of, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { RoughTimePipe } from '@s2a-core/ng-pipes';
import { AuthService, Translation } from '@s2a/core';

import {
  getHistories,
  LastEvaluatedKey,
} from '../graphql/queries/history-item.queries';
import {
  FallbackLanguage,
  HistoryItem,
  ReadStatus,
  ServiceIcon,
  Timeline,
} from '../models/notification-history.model';
import { onCreateHistoriesSubscription } from '../graphql/subscriptions/history-item.subscription';
import { GraphQLHistoryService } from './graphQl-history.service';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class HistoryItemService {
  latestTimestamp = -1;
  unreadCount = 0;
  lastEvaluatedKey: LastEvaluatedKey;
  historyItems: HistoryItem[] = [];
  historyItemQueryRef: QueryRef<any, any>;
  historyItems$ = new Subject<Timeline[]>();
  newHistory$ = new Subject<number>();
  historyItemsRetrieved$ = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private apolloClient: Apollo,
    private roughTimePipe: RoughTimePipe,
    private translation: Translation,
    private graphQLHistoryService: GraphQLHistoryService
  ) {
    this.authService.isLoggedIn$.subscribe((isValidSession) => {
      this.graphQLHistoryService.setupGraphClient(isValidSession);
    });
  }

  getUnseenCount(): Observable<number> {
    this.graphQLHistoryService.getUnseenCount().subscribe((unreadCount) => {
      this.unreadCount = unreadCount;
      this.newHistory$.next(this.unreadCount);
    });
    return this.newHistory$;
  }

  groupHistoryItem(historyItem: Timeline[]) {
    const timelineMap = historyItem
      .map((history) => {
        return {
          ...history,
          key: this.roughTimePipe.transform(history.timestamp),
        };
      })
      .reduce((previousValue, currentValue) => {
        if (previousValue[currentValue.key]) {
          previousValue[currentValue.key].push(currentValue);
        } else {
          previousValue[currentValue.key] = [currentValue];
        }
        return previousValue;
      }, {});
    return Object.keys(timelineMap).map((currentValue) => {
      return {
        title: currentValue,
        items: timelineMap[currentValue],
      };
    });
  }

  timelineFromHistory(history: HistoryItem): Timeline {
    const template = JSON.parse(history.resolvedTemplate || '{}');
    const resolvedTemplateForCurrentLanguage: any = template[
      this.translation.getCurrentLanguage()
    ]
      ? template[this.translation.getCurrentLanguage()]
      : template[FallbackLanguage] || {};
    return {
      ...history,
      title: resolvedTemplateForCurrentLanguage?.subject || '',
      titleLink: resolvedTemplateForCurrentLanguage?.subject || '',
      description: resolvedTemplateForCurrentLanguage?.text || '',
      iconUrl: ServiceIcon[history.service.toUpperCase()],
    };
  }

  updateStatus(timestamp: number, readStatus: ReadStatus) {
    this.graphQLHistoryService.updateHistory(timestamp, readStatus).subscribe();
    this.historyItems = this.historyItems.map((history) => {
      if (history.timestamp === timestamp) {
        return { ...history, readStatus };
      }
      return history;
    });

    this.historyItems$.next(
      this.historyItems.map((history) => this.timelineFromHistory(history))
    );
  }

  updateHistories(): Observable<string> {
    const unseenTimestamps = this.historyItems
      .filter((item) => item.readStatus === ReadStatus.UNSEEN)
      .map((historyItem) => {
        return historyItem.timestamp;
      });
    if (unseenTimestamps.length) {
      return this.graphQLHistoryService
        .bulkHistoryUpdate(unseenTimestamps, ReadStatus.UNREAD)
        .pipe(untilDestroyed(this),
          map((responseData) => {
            return responseData.data.bulkUpdateStatus.response.message;
          })
        );
    }
    return of('');
  }

  updateTimelineWithTranslation(): void {
    this.translation.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.historyItems$.next(
        this.historyItems.map((history) => this.timelineFromHistory(history))
      );
    });
  }

  establishGetHistorySubscription(pageSize: number): void {
    this.graphQLHistoryService.client$.subscribe(() => {
      this.historyItems = [];
      const userId = this.authService.user.userId;
      this.getAllRecordByPaging(pageSize, {
        timestamp: new Date().getTime() + 1000,
        userId,
      });
      this.subscribeHistory();
    });
    this.updateTimelineWithTranslation();
  }

  /*
   * Recursively get all pages history
   */
  getAllRecordByPaging(
    pageSize: number,
    lastEvaluatedKey?: LastEvaluatedKey
  ): void {
    this.graphQLHistoryService
      .getHistory(pageSize, lastEvaluatedKey)
      .subscribe((historyResponse: any) => {
        this.historyItems.push(...historyResponse.history);
        this.historyItemsRetrieved$.next(true);
        if (this.historyItems && this.historyItems.length) {
          this.latestTimestamp =
            this.historyItems[this.historyItems.length - 1].timestamp;
          this.historyItems$.next(
            this.historyItems.map((history) =>
              this.timelineFromHistory(history)
            )
          );
        }
        this.lastEvaluatedKey = historyResponse.lastEvaluatedKey;
      });
  }

  subscribeHistory(): void {
    const userId = this.authService.user.userId;
    this.apolloClient.subscribe({
      query: onCreateHistoriesSubscription,
      variables: { userId },
    }).subscribe((responseData: any) => {
        const data = responseData.data.onCreateHistoryItem;
        if (this.latestTimestamp !== data.timestamp){
          this.latestTimestamp = data.timestamp;
          this.unreadCount++;
          this.newHistory$.next(this.unreadCount);
          this.historyItems.unshift(
            data
          );
          this.historyItems$.next(
            this.historyItems.
            map((history) => this.timelineFromHistory(history))
          );
        }
      });
  }
}
