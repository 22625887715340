import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

import { Component } from '@angular/core';

import { AuthService } from '@s2a/core';

import {
  FilterOptions,
  HistoryPageSize,
  ReadStatus,
  Timeline,
  TimelineEvent,
  TimelineGroup,
} from '../../models/notification-history.model';
import { HistoryItemService } from '../../services/history-item.service';
import { APPBELL_RIGHT } from '../../config/history.config';

@UntilDestroy()
@Component({
  selector: 's2a-notification-timeline',
  templateUrl: './notification-timeline.component.html',
  styleUrls: ['./notification-timeline.component.scss'],
})
export class NotificationTimelineComponent {
  valueRetrieved = false;
  loadingPaginatedData = false;
  _timelineGroups: TimelineGroup[];
  histories: Timeline[] = [];

  get timelineGroups(): TimelineGroup[] {
    return this._timelineGroups;
  }

  set timelineGroups(timelineGroups: TimelineGroup[]) {
    this._timelineGroups = timelineGroups;
  }

  constructor(
    private historyItemService: HistoryItemService,
    private authService: AuthService
  ) {
    this.establishHistorySubscription();
  }

  onFilterTimeline(value: ReadStatus) {
    let histories = this.histories;
    if (value !== ReadStatus.ALL) {
      histories = this.histories.filter((history: Timeline) => {
        return history.readStatus === value;
      });
    }
    this.timelineGroups = this.historyItemService.groupHistoryItem(histories);
  }

  onStatusUpdate(event: TimelineEvent) {
    if (event.readStatus === ReadStatus.UNREAD) {
      this.historyItemService.updateStatus(event.timestamp, ReadStatus.READ);
    }
  }

  onSwitchReadStatus(event: TimelineEvent) {
    if (event.readStatus === ReadStatus.READ) {
      // only set to unread if status was read before
      this.historyItemService.updateStatus(event.timestamp, ReadStatus.UNREAD);
    } else {
      // if the read status is unseen / seen / unread
      // we are allowed to set the readStatus to read
      this.historyItemService.updateStatus(event.timestamp, ReadStatus.READ);
    }
  }

  onApplyFilter(filterOptions: FilterOptions): void {
    if (!filterOptions.services && !filterOptions.date) {
      this.timelineGroups = this.historyItemService.groupHistoryItem(
        this.histories
      );
      return;
    }

    let histories = this.histories;
    if (filterOptions.services) {
      histories = this.histories.filter((history) =>
        filterOptions.services.includes(history.service)
      );
    }

    if (filterOptions.date) {
      histories = this.histories.filter(
        (history) =>
          history.timestamp >= filterOptions.date.from &&
          history.timestamp <= filterOptions.date.to
      );
    }
    this.timelineGroups = this.historyItemService.groupHistoryItem(histories);
  }

  private establishHistorySubscription(): void {
    if (this.authService.hasGlobalRight(APPBELL_RIGHT)) {
      this.historyItemService.establishGetHistorySubscription(HistoryPageSize);
      this.historyItemService.historyItemsRetrieved$.subscribe((value) => {
        this.valueRetrieved = value;
        this.loadingPaginatedData = false;
      });
      this.historyItemService.historyItems$
        .pipe(
          map((histories: Timeline[]) => {
            this.histories = histories;
            return this.historyItemService.groupHistoryItem(histories);
          })
        )
        .subscribe((data: TimelineGroup[]) => {
          this.timelineGroups = data;
        });
    }
  }

  public fetchMore() {
    if (!this.historyItemService.lastEvaluatedKey) {
      return;
    }
    this.loadingPaginatedData = true;
    this.historyItemService.getAllRecordByPaging(HistoryPageSize, this.historyItemService.lastEvaluatedKey);
  }
}
