<div class="header-wrapper" fxLayout="column" fxLayoutAlign="start start">
  <div class="header-bar" style="width: 100%">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div translate class="text" fxFlex>components.notification.title</div>
      <div>
        <button mat-icon-button color="accent" (click)="onFilterClicked()">
          <mat-icon svgIcon="filter"></mat-icon>
        </button>
        <mat-icon *ngIf="filterApplied" class="filter-applied">
          fiber_manual_record
        </mat-icon>
      </div>

    </div>
    <!-- <div class="search-bar">
      <mat-form-field appearance="outline">
        <mat-label translate>global.search</mat-label>
        <input matInput placeholder="Placeholder">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div> -->
    <div class="toggle" fxLayout="row" fxLayoutAlign="end start">
      <mat-slide-toggle translate (change)="toggleNotification($event)"
        ><span translate
          >components.notification.show_only_unread</span
        ></mat-slide-toggle
      >
    </div>
  </div>
</div>
