import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SessionService, AppShellApplications, ComponentsConfig } from '@s2a/ng-shell';

import { ComponentLifecycleManager } from './component.service';
import { AppRegistry } from '../models/app-registry.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageLoaderService {

  constructor(
    private componentLifecycleManager: ComponentLifecycleManager,
    private httpClient: HttpClient,
    private sessionService: SessionService) { }

  get userApps(): string[] {
    // Enabling all application
    const apps = [];
    AppShellApplications.forEach(app => {
      apps.push(app.key);
    });
    return apps;
  }

  get registeredApps(): AppRegistry[] {
    // Enabling all application
    const apps = [];
    AppShellApplications.forEach(app => {
      apps.push({
        id: app.key,
        key: app.key,
        icon: app.key,
        text: app.text,
        routePath: app.routePath ?? app.key
      } as AppRegistry);
    });
    return apps;
  }

  get components(): AppRegistry[] {
    // Enabling all application
    const components = [];
    ComponentsConfig.forEach(app => {
      components.push({
        id: app.key,
        icon: app.key,
        key: app.key,
      } as AppRegistry);
    });
    return components;
  }

  render(url: string): void {
    this.componentLifecycleManager.addContent(url);
    this.componentLifecycleManager.attachComponent();
  }

  async isMigratedApp(app: string) {
    try {
      await this.httpClient.head(`${environment.domainUrl}apps/${app}`).toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }

}
