<s2a-sidenav-skeleton
  [imgBaseUrl]="_imgBaseUrl"
  [(expanded)]="expanded"
  [userImageUrl]="userImageUrl"
  [userInitials]="userInitials"
  [activeAppName]="activeAppName"
  (profileClick)="onProfileClicked()"
  [itemClickedEvents]="itemClickedSubject.asObservable()"
  [isAppBellSupported]="isAppBellSupported()"
  [isAppBellVisible]="toggleAppBell"
  [badgeCount]="appBell?.badgeCount"
  (platformChange)="onPlatformChangeHandler($event)"
  (appBellClick)="onAppBellClicked($event)"
>
  <mat-nav-list main>
    <a [routerLink]="['/profile']">
      <mat-list-item
        class="user-circle pl-40"
        [matTooltip]="
          expanded
            ? null
            : ('components.platform_header.my_profile' | translate)
        "
        matTooltipPosition="after"
        matTooltipClass="tooltip"
        (click)="onProfileClicked()"
      >
        <div>
          <s2a-wc-image-button
            [text]="userImageUrl ? '' : userInitials"
            [imageUrl]="userImageUrl"
          ></s2a-wc-image-button>
        </div>
        <span *ngIf="expanded" class="expandable-text" translate
          >components.platform_header.my_profile</span
        >
      </mat-list-item>
    </a>
    <s2a-sidenav-list-item *ngIf="isAppBellSupported() && !isMobile"
      [class.side-nav]="expanded"
      [showText]="expanded"
      [app]="appBell.id"
      [icon]="appBell.icon"
      [text]="appBell.textKey | translate"
      [badgeCount]="appBell.badgeCount"
      [openTargetInNewWindow]="false"
      (itemClick)="onAppBellClicked(!toggleAppBell)"
    >
    </s2a-sidenav-list-item>
    <hr/>

    <ng-container *ngFor="let group of items; let j = index;">
        <hr *ngIf="j !== 0 && items[j].items[0].section !== items[j - 1].items[items[j - 1].items.length -1 ].section"/>
        <div class="side-bar-grp-container">
          <span class="groupName" *ngIf="group?.groupName">{{group?.groupName}}</span>
          <div fxLayout="column" fxLayoutAlign="center" class="w-100">
            <ng-container *ngFor="let item of group.items; let i = index;">
              <s2a-sidenav-list-item
              [class.side-nav]="alignItemsRight(group)"
              [showText]="expanded"
              [app]="item.id"
              [routePath]="item.routePath"
              [icon]="item.icon"
              [text]="!group.displayLocaleText ? item.text : item.textKey | translate"
              [openTargetInNewWindow]="item.canOpenInNewWindow"
              (itemClick)="onItemClicked($event)"
              >
              </s2a-sidenav-list-item>
            </ng-container>
          </div>
        </div>
      </ng-container>
  </mat-nav-list>
  <ng-content select="pure-notification" ngProjectAs="pure-notification-skeleton"></ng-content>
  <ng-content select="pure-sidenav-router" ngProjectAs="pure-sidenav-skeleton-router"></ng-content>
</s2a-sidenav-skeleton>
