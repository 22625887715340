import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { EmbedAppMountedPath } from '@s2a/core';
import {
  EquipmentStateQuery,
  EquipmentStateService,
} from '@s2a-equipment/akita';

import { ComponentLifecycleManager } from '../../services/component.service';
import { PageLoaderService } from '../../services/pageLoader.service';
import { RouterService } from '../../services/router.service';
import { environment } from '../../../environments/environment';

@UntilDestroy()
@Component({
  selector: 's2a-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [TitleCasePipe],
})
export class ShellComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('contentPlace') contentPlace: ElementRef;

  activatedAppUrl: string;
  isInitialized = false;

  constructor(
    private componentLifecycleManager: ComponentLifecycleManager,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private routerService: RouterService,
    public pageLoaderService: PageLoaderService,
    private equipmentService: EquipmentStateService,
    private equipmentQuery: EquipmentStateQuery,
    private titleCasePipe: TitleCasePipe,
    private title: Title
  ) {}

  ngOnInit() {
    this.equipmentService.ensureCache().pipe(untilDestroyed(this)).subscribe();
    combineLatest([
      this.activeRoute.data.pipe(
        map((routeData) => {
          this.activatedAppUrl = routeData.appUrl || '';
          if (this.activatedAppUrl.includes('errors')) {
            return 'errors';
          }
          return this.activatedAppUrl;
        })
      ),
      this.equipmentQuery
        .selectFilter('description', 'enterprise')
        .pipe(map((equipments) => (equipments.length ? equipments[0] : null))),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([service, equipment]) => {
        this.setTitle(service, equipment ? equipment.description : '');
      });

    this.routerService
      .getActiveRoute$()
      .pipe(untilDestroyed(this))
      .subscribe((event: MessageEvent) => {
        this.navigateShell(event);
      });
  }

  navigateShell(event: MessageEvent) {
    if (event.data && 'value' in event.data) {
      const value = event.data.value;
      const currentPath = location.href.replace(`${location.origin}/`, '');
      const embedAppPath = value.startsWith(`${EmbedAppMountedPath}/`)
        ? value.replace(`${EmbedAppMountedPath}/`, '')
        : value;
      const urlSegment = this.getUrlSegment(embedAppPath);

      if (embedAppPath !== currentPath) {
        this.router.navigate([`/${urlSegment.path}`], {
          queryParams: urlSegment.queryParams,
          replaceUrl: true,
        });
      }
    }
  }

  getUrlSegment(url: string) {
    const queryStringStartIndex = url.indexOf('?');
    if (queryStringStartIndex > -1) {
      const queryParamsIterator = new URLSearchParams(
        url.slice(queryStringStartIndex + 1)
      );
      const queryParams = {};
      queryParamsIterator.forEach((value, key) => {
        queryParams[key] = value;
      });
      return {
        path: url.slice(0, queryStringStartIndex),
        queryParams,
      };
    }
    return {
      path: url,
    };
  }

  setTitle(service: string, equipmentTitle: string): void {
    this.title.setTitle(
      `${
        equipmentTitle
          ? this.titleCasePipe.transform(equipmentTitle) + ' - '
          : ''
      }${
        service ? this.titleCasePipe.transform(service) + ' - ' : ''
      } Share2Act`
    );
  }

  ngAfterViewChecked() {
    if (!this.componentLifecycleManager.contentShell) {
      this.componentLifecycleManager.createComponent(this.contentPlace);
      this.renderApp();
    } else {
      if (!this.isInitialized) {
        this.renderApp();
      }
    }
  }

  private getUrl(): string {
    const url = location.href;
    const urlWithDomain = url.replace(location.origin, '');
    if (!urlWithDomain.startsWith(`/${this.activatedAppUrl}`)) {
      return `/${this.activatedAppUrl}`;
    }
    return urlWithDomain;
  }

  renderApp() {
    this.pageLoaderService.render(
      environment.domainUrl.concat(`${EmbedAppMountedPath}${this.getUrl()}`)
    );
    this.isInitialized = true;
  }

  ngOnDestroy() {
    this.componentLifecycleManager.detachComponent();
    this.componentLifecycleManager.clear();
  }
}
