import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthoriseGuard } from '@s2a/ng-shell';
import { ShellComponent } from './components/shell/shell.component';

const generateDefaultChild = (appName: string) => {
  return [{ path: '**', component: ShellComponent, data: { appUrl: appName } }];
};

export const routes: Routes = [
  {
    path: 'drinktec-welcome',
    component: ShellComponent,
    data: { appUrl: 'drinktec-welcome' },
    canActivate: [AuthoriseGuard],
  },
  {
    path: 'profile',
    component: ShellComponent,
    data: { appUrl: 'profile' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('profile'),
  },
  {
    path: 'connect',
    component: ShellComponent,
    data: { appUrl: 'connect' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('connect'),
  },
  {
    path: 'user-management',
    component: ShellComponent,
    data: { appUrl: 'user-management' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('user-management'),
  },
  {
    path: 'equipment',
    component: ShellComponent,
    data: { appUrl: 'equipment' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('equipment'),
  },
  {
    path: 'storage',
    component: ShellComponent,
    data: { appUrl: 'storage' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('storage'),
  },
  {
    path: 'maintenance',
    component: ShellComponent,
    data: { appUrl: 'maintenance' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('maintenance'),
  },
  {
    path: 'time2lubrify',
    component: ShellComponent,
    data: { appUrl: 'time2lubrify' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('time2lubrify'),
  },
  {
    path: 'v2-watchdog',
    component: ShellComponent,
    data: { appUrl: 'v2-watchdog' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('v2-watchdog'),
  },
  {
    path: 'ds-test-tool',
    component: ShellComponent,
    data: { appUrl: 'ds-test-tool' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('ds-test-tool'),
  },
  {
    path: 'demo-trigger',
    component: ShellComponent,
    data: { appUrl: 'demo-trigger' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('demo-trigger'),
  },
  {
    path: 'site-service',
    component: ShellComponent,
    data: { appUrl: 'site-service' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('site-service'),
  },
  {
    path: 'import',
    component: ShellComponent,
    data: { appUrl: 'import' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('import'),
  },
  {
    path: 'performance',
    component: ShellComponent,
    data: { appUrl: 'performance' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('performance'),
  },
  {
    path: 'task-service',
    component: ShellComponent,
    data: { appUrl: 'task-service' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('task-service'),
  },
  {
    path: 'notifications',
    component: ShellComponent,
    data: { appUrl: 'notifications' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('notifications'),
  },
  {
    path: 'external-api',
    component: ShellComponent,
    data: { appUrl: 'external-api' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('external-api'),
  },
  {
    path: 'erp-analytics',
    component: ShellComponent,
    data: { appUrl: 'erp-analytics' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('erp-analytics'),
  },
  {
    path: 'contiloop-ai',
    component: ShellComponent,
    data: { appUrl: 'contiloop-ai' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('contiloop-ai'),
  },
  {
    path: 'advanced-analytics-performance',
    component: ShellComponent,
    data: { appUrl: 'performance' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('performance'),
  },
  {
    path: 'integrated-erp-analytics',
    component: ShellComponent,
    data: { appUrl: 'erp-analytics' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('erp-analytics'),
  },
  {
    path: 'track-and-trace',
    component: ShellComponent,
    data: { appUrl: 'track-and-trace' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('track-and-trace'),
  },
  {
    path: 'lms',
    component: ShellComponent,
    data: { appUrl: 'lms' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('lms'),
  },
  {
    path: 'guidance-task-service',
    component: ShellComponent,
    data: { appUrl: 'task-service' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('task-service'),
  },
  {
    path: 'guidance-v2-watchdog',
    component: ShellComponent,
    data: { appUrl: 'v2-watchdog' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('v2-watchdog'),
  },
  {
    path: 'guidance-connect',
    component: ShellComponent,
    data: { appUrl: 'connect' },
    canActivate: [AuthoriseGuard],
    children: generateDefaultChild('connect'),
  },
  {
    path: 'forbidden',
    component: ShellComponent,
    data: { appUrl: 'errors/error403.html' },
  },
  {
    path: 'not-found',
    component: ShellComponent,
    data: { appUrl: 'errors/error404.html' },
  },
  { path: '', redirectTo: 'drinktec-welcome', pathMatch: 'full' },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
