import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { ReadStatus } from '../../../../models/notification-history.model';

@Component({
  selector: 's2a-pure-timeline-header',
  templateUrl: './pure-timeline-header.component.html',
  styleUrls: ['./pure-timeline-header.component.scss'],
})
export class PureTimelineHeaderComponent {
  /**
   * Indicates if a filter options are applied
   */
  @Input() filterApplied = false;

  /**
   * Return the filter name when user click on toggle button
   *
   * @returns ReadStatus
   */
  @Output() toggledFilter = new EventEmitter<ReadStatus>();

  /**
   * Emits an filter click event
   *
   * @returns void
   */
  @Output() filterClicked = new EventEmitter<void>();

  /**
   * @ignore
   */
  toggleNotification(event: MatSlideToggleChange): void {
    let appliedFilter = ReadStatus.ALL;
    if (event.checked) {
      appliedFilter = ReadStatus.UNREAD;
    }
    this.toggledFilter.emit(appliedFilter);
  }

  /**
   * @ignore
   */
  onFilterClicked(): void {
    this.filterClicked.emit();
  }
}
