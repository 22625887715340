<div
  class="filter-wrapper"
  fxLayout="column"
  fxLayoutAlign="space-between start"
>
  <div class="title-bar" style="width: 100%">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div translate class="text" fxFlex>global.filter</div>
      <button mat-icon-button color="accent" (click)="onCancelClicked()">
        <mat-icon svgIcon="cancel"></mat-icon>
      </button>
    </div>
  </div>

  <s2a-pure-filter-panel
    class="expansion-panel"
    [title]="'components.notification.service' | translate"
  >
    <div ngProjectAs="content" class="expansion-content">
      <mat-chip-list [multiple]="true">
        <mat-chip
          *ngFor="let service of allServices"
          [selected]="isSelectedService(service.key)"
          (click)="onSelectService(service.key)"
        >
          {{ service.value | translate }}
        </mat-chip>
      </mat-chip-list>
    </div>
  </s2a-pure-filter-panel>

  <s2a-pure-filter-panel
    class="expansion-panel"
    [title]="'global.time_labels.date' | translate"
  >
    <div ngProjectAs="content" class="expansion-content">
      <mat-chip-list [multiple]="false">
        <mat-chip
          *ngFor="let options of dateOptions"
          [selected]="isSelectedDateOption(options.key)"
          (click)="onSelectDateOption(options.key)"
        >
          {{ options.value | translate }}
        </mat-chip>
      </mat-chip-list>
      <ng-container *ngIf="selectedDateOption === 'custom'">
        <div class="date-picker-wrapper">
          <s2a-wc-date-range-picker
            [label]="'global.time_labels.date' | translate"
            [placeholder]="'global.select' | translate"
            [maxDate]="today"
            [dateRange]="dateRange"
            (dateRangeChange)="onSelectDateRange($event)"
          >
          </s2a-wc-date-range-picker>
        </div>
      </ng-container>
    </div>
  </s2a-pure-filter-panel>

  <div class="footer" fxLayout="column" fxLayoutAlign="space-between center">
    <button
      class="reset-filter-button"
      mat-button
      color="accent"
      (click)="onResetFilter()"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon svgIcon="backToStart"></mat-icon>
        <p translate>global.reset_all_filters</p>
      </div>
    </button>
    <button
      class="apply-filter-button"
      mat-flat-button
      color="primary"
      (click)="onApplyFilter()"
    >
      <p translate>global.apply</p>
    </button>
  </div>
</div>
