import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Apollo } from 'apollo-angular';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

import { LOCALSTORAGE_AUTH_TOKEN } from '@s2a/core';

import { ReadStatus } from '../models/notification-history.model';
import { environment } from '../../environments/environment';
import { bulkHistoryUpdateMutation, historyUpdateMutation } from '../graphql/mutations/history-item.mutation';
import { getHistories, LastEvaluatedKey, unseenCountQuery } from '../graphql/queries/history-item.queries';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GraphQLHistoryService {
  client$ = new Subject<any>();
  constructor(
    private apolloClient: Apollo
  ) { }

  setupGraphClient(isValidSession: boolean) {
    if (isValidSession){
      this.removeClient();
      const client = this.createClient();
      this.apolloClient.create(client);
      this.client$.next(client);
    } else {
      this.removeClient();
    }
  }

  removeClient(): void {
    if (this.apolloClient.client){
      this.apolloClient.removeClient();
    }
  }

  createClient(): any {
    return new AWSAppSyncClient<any>({
      disableOffline: true,
      // tslint:disable-next-line: no-non-null-assertion
      url: environment.notificationHistoryUrl!,
      region: 'eu-central-1',
      auth: {
        type: AUTH_TYPE.AWS_LAMBDA,
        token: `Bearer ${localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN)}`,
      },
    });
  }

  updateHistory(timestamp: number, readStatus: ReadStatus): Observable<any> {
    return this.client$.pipe(
      mergeMap(() => {
        return this.apolloClient.mutate({
          mutation: historyUpdateMutation(timestamp, readStatus),
        });
      })
    );
  }

  bulkHistoryUpdate(timestamps: number[], readStatus: ReadStatus): Observable<any> {
    return this.apolloClient.mutate({
      mutation: bulkHistoryUpdateMutation(timestamps, readStatus),
    });
  }

  getUnseenCount(): Observable<number> {
    return this.client$.pipe(
      mergeMap(() => {
        return this.apolloClient.query({
          query: unseenCountQuery,
        }).pipe(
          map((response: any) => response.data.getUnseenCount.unseenCount)
        );
      })
    );
  }

  getHistory(
    pageSize: number,
    lastEvaluatedKey?: LastEvaluatedKey
  ): Observable<any> {
    return this.apolloClient
    .query({
      query: getHistories(pageSize, lastEvaluatedKey),
    })
    .pipe(
      untilDestroyed(this),
      map((responseData: any) => {
        return responseData.data.getHistory;
      })
    );
  }
}
