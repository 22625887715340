import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  NgCoreModule,
  AuthHttpInterceptor,
  FileModule
} from '@s2a-core/ng-core';
import { NgShellModule, SessionService } from '@s2a/ng-shell';
import { AuthService, Environment } from '@s2a/core';
import { EquipmentCoreModule } from '@s2a-equipment/ng-core';
import { RoughTimePipe } from '@s2a-core/ng-pipes';

import { AppRoutingModule } from './app.router';
import { AppMaterialModule } from './app.material.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { PureListItemComponent } from './components/sidenav/list-item/pure-list-item.component';
import { PureSidenavComponent } from './components/sidenav/pure-sidenav/pure-sidenav.component';
import { PureSidenavSkeletonComponent } from './components/sidenav/sidenav-skeleton/pure-sidenav-skeleton.component';
import { ShellComponent } from './components/shell/shell.component';
import { ComponentLifecycleManager } from './services/component.service';
import { ShellAuthenticationService } from './services/shell-authentication.service';
import { NotificationTimelineComponent } from './components/notification-timeline/notification-timeline.component';
import { PureHistoryDividerComponent } from './components/notification-timeline/timeline/divider/pure-history-divider.component';
import { PureHistoryItemComponent } from './components/notification-timeline/timeline/history-item/pure-history-item.component';
import { PureTimelineHeaderComponent } from './components/notification-timeline/timeline/header/pure-timeline-header.component';
import { PureTimelineComponent } from './components/notification-timeline/timeline/pure-timeline.component';
import { PureTimelineFilterComponent } from './components/notification-timeline/timeline/filter/pure-timeline-filter.component';
import { PureFilterPanelComponent } from './components/notification-timeline/timeline/filter/filter-panel/pure-filter-panel.component';
import { AppBellComponent } from './components/app-bell/app-bell.component';
import { VirtualHistoryItemComponent } from './components/notification-timeline/timeline/virtual-history-item/virtual-history-item.component';
import { setSessionFactory } from './services/session-factory.service';
import { RouterService } from './services/router.service';

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    PureSidenavComponent,
    PureSidenavSkeletonComponent,
    PureListItemComponent,
    ShellComponent,
    NotificationTimelineComponent,
    PureHistoryDividerComponent,
    PureHistoryItemComponent,
    PureTimelineHeaderComponent,
    PureTimelineComponent,
    PureTimelineFilterComponent,
    PureFilterPanelComponent,
    AppBellComponent,
    VirtualHistoryItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    AppMaterialModule,
    NgShellModule.forRoot(),
    NgCoreModule.forRoot(environment as Environment),
    FileModule.forRoot(environment as Environment),
    EquipmentCoreModule.forRoot(environment as Environment),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    ComponentLifecycleManager,
    ShellAuthenticationService,
    {
      provide: Environment,
      useValue: environment
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setSessionFactory,
      deps: [ShellAuthenticationService, SessionService, RouterService, AuthService],
      multi: true,
    },
    RoughTimePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {}
}
