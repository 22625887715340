import { interval, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { S2AHttpClient } from '@s2a-core/ng-core';

@Injectable({
  providedIn: 'root',
})
export class HeartBeatService {
  constructor(private http: S2AHttpClient) {}

  heartBeat(): Observable<void> {
    const heartBeatCheckInterval = interval(1000);
    return heartBeatCheckInterval.pipe(
      mergeMap(() => {
        const currentTime = Date.now();
        const heartBeatTime = localStorage.getItem(
          'LOCALSTORAGE_HEARTBEAT_TIMESTAMP'
        );
        if (
          !heartBeatTime ||
          currentTime - parseInt(heartBeatTime, 10) > 60 * 1000
        ) {
          localStorage.setItem(
            'LOCALSTORAGE_HEARTBEAT_TIMESTAMP',
            currentTime.toString()
          );
          return this.postHeartBeat();
        }
        return new Observable<void>();
      })
    );
  }

  private postHeartBeat(): Observable<void> {
    return this.http.post<void>('uaa/heartbeat', {});
  }
}
