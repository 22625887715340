import { Environment } from '@s2a/core';

export interface CustomEnvironment extends Environment {
  subAppUrl: string;
  refreshInterval?: number;
  notificationHistoryUrl?: string;
  b2cConfiguration?: B2CConfiguration;
}

interface B2CConfiguration {
  userFlow: string;
  envDomain: string;
  tenantDomainPrefix: string;
  clientId: string;
  kwUrl: string;
}

const baseDomain = location.hostname.split('.').slice(1).join('.');

const domain = baseDomain === 'localhost' ? 'share2act-dev.io' : baseDomain;

const getHistoryEndpoint = (historyDomain: string) => {
  if (historyDomain === 'share2act.io') {
    // productive
    return 'https://gy6zo4u5brghfbvh3mjmeqb7fa.appsync-api.eu-central-1.amazonaws.com/graphql';
  } else if (historyDomain === 'share2act-test.io') {
    // test
    return 'https://pjezob2j3jgj7i2v6f6zcoraji.appsync-api.eu-central-1.amazonaws.com/graphql';
  }
  return 'https://m6l7toft3zgutltikdtcclgynm.appsync-api.eu-central-1.amazonaws.com/graphql';
};

const createb2cConfiguration = (appDomain: string) => {
  let configuration: B2CConfiguration;
  if (appDomain === 'share2act.io') {
    // production
    configuration = {
      userFlow: 'prod',
      envDomain: '', // will be empty for production
      tenantDomainPrefix: 'kronesworld',
      clientId: '9a293215-d1d9-45fc-bf81-3aa41d53f3e2',
      kwUrl: 'https://krones.world/'
    };
  } else if (appDomain === 'share2act-test.io') {
    // test
    configuration = {
      userFlow: 'test',
      envDomain: 'qa',
      tenantDomainPrefix: 'krwqa',
      clientId: '021b7324-38c2-4c75-8886-bc6ad6a11bae',
      kwUrl: 'https://test.krones.world/'
    };
  } else {
    configuration = {
      userFlow: 'dev',
      envDomain: 'dev',
      tenantDomainPrefix: 'krwdev',
      clientId: '4ee10edc-52c6-4a35-8882-9e019ff3d692',
      kwUrl: 'https://dev.krones.world/'
    };
  }
  return configuration;
};

export const environment: CustomEnvironment = {
  production: domain === 'share2act.io',
  apiUrl: `https://api.${domain}/v1/`,
  iconUrl: `https://icons.${domain}/v2/`,
  imageUrl: `https://assets.${domain}/images/`,
  styleUrl: `https://assets.${domain}/styles/`,
  translationUrl: `https://translations.${domain}/v1/`,
  domainUrl: `https://drinktec.${domain}/`,
  subAppUrl: `https://app.${domain}/`,
  refreshInterval: 60 * 1000, // 1 Min
  notificationHistoryUrl: getHistoryEndpoint(domain),
  b2cConfiguration: createb2cConfiguration(domain)
};
