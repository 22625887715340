import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 's2a-app-bell',
  templateUrl: './app-bell.component.html',
  styleUrls: ['./app-bell.component.scss'],
})
export class AppBellComponent {
  @Input() appBellOpened: boolean;

  @Output() appBellClosed: EventEmitter<any> = new EventEmitter();

  @ViewChild('appBell', { static: true }) appBell: MatSidenav;

  /**
   * The function is called to close the app bell panel when click on backdrop
   */
  onBackdropClick(): void {
    this.appBell.close();
    this.appBellClosed.emit();
  }
}
