import { gql } from 'apollo-angular';

import { ReadStatus } from '../../models/notification-history.model';

export const historyUpdateMutation = (
  timestamp: number,
  readStatus: ReadStatus
) =>
  gql`
  mutation UpdateHistoryItem {
    updateHistory(input: {
      timestamp: ${timestamp},
      readStatus: ${readStatus}
    }) {
      expiration
      account
      service
      action
      metadata
      readStatus
      timestamp
      userId
    }
  }
`;

export const bulkHistoryUpdateMutation = (
  timestamps: number[],
  readStatus: ReadStatus
) =>
  gql`
  mutation BulkUpdateHistoryItems {
    bulkUpdateStatus(input: {
      timestamps: ${JSON.stringify(timestamps)},
      readStatus: ${readStatus}
    }) {
      response {
        message
        unseenCount
      }
    }
  }
`;
