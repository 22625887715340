import { gql } from 'apollo-angular';

export interface LastEvaluatedKey {
  userId: string;
  timestamp: number;
}

export const getHistories = (
  count: number,
  lastEvaluatedKey?: LastEvaluatedKey
) => {
  return gql`
    query getHistory {
      getHistory(
        input: {
          count: ${count},
          lastEvaluatedKey: {
            timestamp: ${lastEvaluatedKey.timestamp},
            userId: "${lastEvaluatedKey.userId}"
          }
        }
      ) {
        lastEvaluatedKey {
          timestamp
          userId
        }
        history {
          resolvedTemplate
          account
          action
          expiration
          service
          timestamp
          userId
          metadata
          readStatus
        }
      }
    }
  `;
};

export const unseenCountQuery = gql`
  query getUnseenCount {
    getUnseenCount {
      unseenCount
    }
  }
`;
