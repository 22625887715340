<ng-container *ngIf="isAuthenticated">
  <s2a-pure-sidenav
    [userImageUrl]="userImageUrl"
    [imgBaseUrl]="baseImageUrl"
    [items]="sidenavItems"
    [appBell]="appBellItem"
    (itemClick)="onItemClicked($event)"
    (profileClick)="onProfileClicked()"
    [activeAppName]="activeAppName"
    [userInitials]="userInitials"
  >
  <ng-content select="notification" ngProjectAs="pure-notification"></ng-content>
  <ng-content select="sidenav-router" ngProjectAs="pure-sidenav-router"></ng-content>
</s2a-pure-sidenav>
</ng-container>

<ng-template #languageDialog>
  <s2a-language-selector
    [selectedLanguageKey]="currentLanguageKey"
  ></s2a-language-selector>
</ng-template>
