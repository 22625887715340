<div
  fxLayout="row"
  fxLayoutAlign="start center"
  class="history"
  [ngClass]="{
    'history-item-border': !isLoading,
    'read-status-background': !isRead && !isLoading
  }"
>
  <div>
    <mat-icon
      *ngIf="!isRead && !isLoading; else readPlaceholder"
      class="read-status-icon"
    >
      fiber_manual_record
    </mat-icon>
  </div>
  <div class="history-details">
    <s2a-wc-base-reference
      class="base-reference"
      [title]="title | translate"
      [description]="description | translate"
      [customIconUrl]="iconUrl"
      [showInverseBackground]="true"
      [titleLink]="titleLink"
      [showLoading]="isLoading"
      (clicked)="onLinkClick()"
    ></s2a-wc-base-reference>
    <div *ngIf="!isLoading" class="footprint">
      {{ service }} • {{ timestamp | date: 'shortTime' }}
    </div>
  </div>
  <div>
    <button
      *ngIf="!isLoading"
      mat-icon-button
      title="menu"
      color="accent"
      yPosition="below"
      [matMenuTriggerFor]="contextMenu"
    >
      <mat-icon svgIcon="context_menu"></mat-icon>

      <mat-menu #contextMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngIf="!isRead" (click)="onSwitchReadStatus()">
          <span translate>components.notification.mark_as_read</span>
        </button>

        <button mat-menu-item *ngIf="isRead" (click)="onSwitchReadStatus()">
          <span translate>components.notification.mark_as_unread</span>
        </button>
      </mat-menu>
    </button>
  </div>
</div>

<ng-template #readPlaceholder>
  <mat-icon class="read-status-icon"></mat-icon>
</ng-template>
