import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Translation } from '@s2a/core';

@Component({
  selector: 's2a-sidenav-list-item',
  templateUrl: './pure-list-item.component.html',
  styleUrls: ['./pure-list-item.component.scss'],
})
export class PureListItemComponent {
  /**
   * Return the name of the app, if list-item is clicked
   *
   * @returns string
   */
  @Output() itemClick = new EventEmitter();

  /**
   * Name of the app, which this list item represents
   */
  @Input() app: string;

  /**
   * Name of the routed path, which this list item represents
   */
  @Input() routePath: string;

  /**
   * If set to true, the text will be shown next to app-icon. If set to false, only the app-icon will be visible.
   */
  @Input() showText: boolean;

  /**
   * The name of the icon
   */
  @Input()
  set icon(value: string) {
    this._icon = value ?? this.app;
  }
  get icon(): string {
    return this._icon;
  }
  /**
   * @ignore
   */
  _icon?: string;

  /**
   * The text, which will be shown next to the icon, in case "showText" is set to true.
   */
  @Input() text: string;

  /**
   * Boolean, if right click should show option to open the app in a new window.
   */
  @Input() openTargetInNewWindow = false;

  /**
   * Unseen notification count.
   */
  @Input() badgeCount = 0;

  /**
   * Unseen notification count.
   */
  @Input() isAppBellVisible = false;

  constructor(private translation: Translation) {}

  /**
   * @ignore
   */
  onItemClicked(event: MouseEvent, app: string) {
    if (event.ctrlKey || event.metaKey) {
      // skip -- mousewheel or ctrl-click to open in new tab
    } else {
      this.itemClick.emit(app);
    }
  }
}
